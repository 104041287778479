<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="600"
    scrollable
  >
    <v-card :loading="isLoading">
      <v-card-title class="caption">
        <h3>{{$_strings.order.OVERWRITE_CONFIRMATION_SHIPPER}}</h3>
        <v-row>
          <v-col class="d-flex pa-0 ma-0 justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-row>
            <v-col cols="12">
              {{$_strings.order.LABEL_GOODS_RECEIVED_WAREHOUSE}}
            </v-col>
            <v-col class="pb-0">
              <v-textarea
                no-resize
                outlined
                dense
                v-model="form.notes"
                placeholder="Masukkan alasan (Wajib diisi)"
                rows="5"
                :counter="255"
                :rules="[(v) => rulesNotes(v, true, 255, $_strings.order.REASON)]"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          class="white--text"
          @click="dialog = false"
        >
          {{$_strings.common.CANCEL}}
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!form.notes"
          :loading="isLoading"
          @click="baspConfirmation"
        >
          {{$_strings.common.YES}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { rulesNotes, getDateTimeTz } from '@/helper/commonHelpers';

export default {
  watch: {
    dialog(newVal) {
      if (!newVal) this.form.notes = '';
    },
  },
  data() {
    return {
      isLoading: false,
      valid: false,
      dialog: false,
      order: {},
      form: {
        notes: '',
        status: 'BA_PENERIMAAN_DITERIMA_GUDANG',
        statusDatetimeTz: this.getDateTimeTz(),
      },
    };
  },
  methods: {
    rulesNotes,
    getDateTimeTz,
    baspConfirmation() {
      const valid = this.$refs.form.validate();
      if (valid) this.$emit('baspConfirmation', this.order.id, this.form);
    },
  },
};
</script>
